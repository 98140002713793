import 'select2';
import 'slick-carousel'; 
import 'slick-carousel/slick/slick.css';
import { gsap, TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger); 

export default ($) => ({ 
	select2() {
		$('.select2').select2({
        	minimumResultsForSearch: -1
        }); 
	},
	inputFilled(){
		$('.form-group input, .form-group textarea, .form-group select').focus(function(){
			$(this).parent().find('> label').addClass('focus');
		})
		$('.form-group input, .form-group textarea, .form-group select').blur(function(){
			if (!$(this).val()) {
				$(this).parent().find('> label').removeClass('focus');
			}else{
				$(this).parent().find('> label').addClass('focus');
			}
		})
	},
	select2Filled(){
		$('.placeholder-select .select2').on('change', function() {
	      	var data = $(".placeholder-select .select2 option:selected");
	      	if (data.val() == '') {
				$(this).parent().find('> label').show();
				console.log($(this));
			}else{
				$(this).parent().find('> label').hide();
				console.log($(this));
			}
	    })
	},
	numberOnly(){
        $(".number-only").keypress(function (e) {
            if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
               return false;
            }
        });
    },
    textOnly(){
        $(".text-only").keypress(function (e) {
            return (e.charCode > 64 && e.charCode < 91) || (e.charCode > 96 && e.charCode < 123) || (e.charCode == 32);
        });
    },
    menuSlider(){
    	const slider = $('.menu-section .menu.slider');
    	slider.slick({
	        arrows:true,
	        dots:false,
	        infinite: false,
	        speed: 400,
	        slidesToShow: 1,
	        slidesToScroll: 1,
	        variableWidth: true,
	        prevArrow: '<div class="arrow-prev"> \
	        				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> \
							  	<g id="ico-caret-left" transform="translate(875 782) rotate(180)"> \
								    <rect id="Rectangle_569" data-name="Rectangle 569" width="24" height="24" transform="translate(851 758)" fill="none"/> \
							    	<g id="right-arrow_2_" data-name="right-arrow (2)" transform="translate(757.338 762)"> \
							      		<g id="Group_14895" data-name="Group 14895" transform="translate(101.478)"> \
							        		<path id="Path_24912" data-name="Path 24912" d="M110.509,7.284,103.477.252a.866.866,0,0,0-1.223,0l-.518.518a.866.866,0,0,0,0,1.223l5.9,5.9-5.911,5.911a.866.866,0,0,0,0,1.223l.518.518a.866.866,0,0,0,1.222,0l7.039-7.038a.872.872,0,0,0,0-1.227Z" transform="translate(-101.478)" fill="#a2a2a2"/> \
							      		</g> \
							    	</g> \
							  	</g> \
							</svg> \
	        			</div>',
	        nextArrow: '<div class="arrow-next"> \
	        				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> \
							  	<g id="ico-caret-right" transform="translate(-851 -758)"> \
								    <rect id="Rectangle_569" data-name="Rectangle 569" width="24" height="24" transform="translate(851 758)" fill="none"/> \
							    	<g id="right-arrow_2_" data-name="right-arrow (2)" transform="translate(757.338 762)"> \
							      		<g id="Group_14895" data-name="Group 14895" transform="translate(101.478)"> \
							        		<path id="Path_24912" data-name="Path 24912" d="M110.509,7.284,103.477.252a.866.866,0,0,0-1.223,0l-.518.518a.866.866,0,0,0,0,1.223l5.9,5.9-5.911,5.911a.866.866,0,0,0,0,1.223l.518.518a.866.866,0,0,0,1.222,0l7.039-7.038a.872.872,0,0,0,0-1.227Z" transform="translate(-101.478)" fill="#a2a2a2"/> \
							      		</g> \
							    	</g> \
							  	</g> \
							</svg> \
	        			</div>'
	    });
    },
    equalheight(elem){ 
	    let currentTallest = 0,
	        currentRowStart = 0,
	        rowDivs = new Array(),
	        el_ = {},
	        topPosition = 0;
	    $(elem).each(function() {
	        let currentDiv;
	        el_ = $(elem);
	        el_.height('auto')
	        topPosition = el_.position().top;

	        if (currentRowStart != topPosition) { 
	            for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) { 
	                rowDivs[currentDiv].height(currentTallest);
	            }
	            rowDivs.length = 0; // empty the array
	            currentRowStart = topPosition;
	            currentTallest = el_.height();
	            rowDivs.push(el_);
	        } else { 
	            rowDivs.push(el_);
	            currentTallest = (currentTallest < el_.height()) ? (el_.height()) : (currentTallest); 
	        }
	        for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
	            rowDivs[currentDiv].height(currentTallest); 
	        }
	    }); 
	},
	searchDropdown() {
	    var elem = $('header .search');  
	    elem.on('click', '> img', function (e) { 
	        e.stopPropagation();
	        var drop = $(this).parents('.search').children('.search-box');  
	        if(drop.is(':hidden')) { 
	            var e_siblings = $('.act').removeClass("act").children('.search-box'); 
	            TweenLite.to(e_siblings, 0.3, { 
	                css : {
	                    'display'       : 'none',
	                    'opacity'       : '0',
	                    'margin-top'    : '20px'
	                },
	                ease: 'Power2.easeOut'
	            });
	            $(this).parents('.search-box').addClass("act");
	            TweenLite.to(drop, 0.3, {
	                css : {
	                    'display'       : 'block',
	                    'opacity'       : '1',
	                    'margin-top'    : '0px'
	                },
	                ease: 'Power2.easeOut'
	            });
        		$('.overlay').fadeIn();
	        } else {     
	            $(this).parents('.search-box').removeClass("act");
	            TweenLite.to(drop, 0.3, {
	                css : {
	                    'opacity'       : '0',
	                    'margin-top'    : '20px'
	                },
	                ease: 'Power2.easeOut',
	                onComplete: function() {
	                    $('.search-box').hide();
	            		$('.overlay').fadeOut();
	                }
	            });
	        }
	    }); 
		$(document).bind('click', function(e) {
			var $clicked = $(e.target);
			if (! $clicked.parents().hasClass("search")) {
				$('.search-box').removeClass("act");
        		$('.overlay').fadeOut();
	            TweenLite.to($('.search-box'), 0.3, {
	                css : {
	                    'opacity'       : '0',
	                    'margin-top'    : '20px'
	                },
	                ease: 'Power2.easeOut',
	                onComplete: function() {
	                    $('.search-box').hide();
	                }
	            });
			}
		});

	    $('.search > svg').click(function(){
	        setTimeout(function(){
	            $('form input.input-focus').focus();
	        }, 300);
	        if ( $('.language > span').hasClass('active') ) {
	            $('.language > span').removeClass('active');
	            $('.language > span').parent().find('.language-bx').fadeOut('fast');
	        }
	    });
	},
	menuMobile() {
		$('.menu-bar').click(function(){
			if ($(this).hasClass('active')) {
				$(this).removeClass('active');
				$('header nav .menu ul').slideUp();
				$('.overlay').fadeOut();
			}else{
				$(this).addClass('active');
				$('header nav .menu ul').slideDown();
				$('.overlay').fadeIn();
			}
		});


		$(document).bind('click', function(e) {
			var $clicked = $(e.target);
			if (! $clicked.parents().hasClass("menu-bar")) {
				$('.menu-bar').removeClass("active");
        		$('.overlay').fadeOut();
				$('header nav .menu ul').slideUp();

			}
		});
	},
    init(){
    	this.inputFilled()
    	this.select2()
    	this.numberOnly()
    	this.textOnly()
    	this.menuSlider()
    	if (window.innerWidth > 999) {
    		this.searchDropdown()
    	}
    	if (window.innerWidth < 991) {
    		this.menuMobile()
    	}
    }
})