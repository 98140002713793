/* function for scroll animation
=============================================================================================================== */
export default ($, gsap) => ({
    onScreen(elem) {
        if (elem.length) {
            var viewport = {};
            viewport.top = $(window).scrollTop();
            viewport.bottom = viewport.top + $(window).height();
            var bounds = {};
            bounds.top = elem.offset().top;
            bounds.bottom = bounds.top + elem.outerHeight();
            return ((bounds.top <= viewport.bottom) && (bounds.bottom >= viewport.top));
        } else
            return false;
    },
    animationFrom(elem, e) {
        if ($(window).width() > 767) {
            let from = e.from,
                time = e.time,
                ease = e.ease;
            const this_ = this

            elem.each(function () {
                gsap.set($(this), {
                    position: 'relative'                
                });
                switch (from) {
                    case "from_top":
                        gsap.set($(this), {
                            // top: '-50px',
                            opacity: 0
                        });
                        break;
                    case "abs_from_top":
                        gsap.set($(this), {
                            position: 'absolute',
                            opacity: 0
                            // top: '-50px',
                        });
                        break;
                    case "from_bottom":
                        gsap.set($(this), {
                            // bottom: '-50px',
                            opacity: 0
                        });
                        break;
                     case "abs_from_bottom":
                        gsap.set($(this), {
                            position: 'absolute',
                            opacity: 0
                            // bottom: '-50px'
                        });
                        break;
                    case "from_left":
                        gsap.set($(this), {
                            // left: '-50px',
                            opacity: 0
                        });
                        break;
                    case "abs_from_left":
                        gsap.set($(this), {
                            position: 'absolute',
                            opacity: 0
                            // left: '-50px'
                        });
                        break;
                    case "from_right":
                        gsap.set($(this), {
                            // right: '-50px',
                            opacity: 0
                        });
                        break;
                    case "abs_from_right":
                        gsap.set($(this), {
                            position: 'absolute',
                            opacity: 0
                            // right: '-50px'
                        });
                        break;
                    case "line_anim":
                        gsap.set($(this), {
                            width: '0px',
                            opacity: 1
                        });
                        break;
                }
            });
            $(window).scroll(function () {
                this_.animatingFrom(elem,{
                    from: from,
                    time: time,
                    ease: ease
                });
            });
            this_.animatingFrom(elem,{
                from: from,
                time: time,
                ease: ease
            });
        }
    },
    animatingFrom(elem, e) {
        let from = e.from,
            time = e.time,
            ease = e.ease,
            trigger_elem = "",
            delay = 0;
        const this_ = this

        elem.each(function () {
            if ($(this).attr('trigger-anim') !== undefined) {
                trigger_elem = $($(this).attr('trigger-anim'));
            } else
                trigger_elem = $(this);

            switch (from) {
                case "from_top":
                    delay = parseFloat($(this).attr('delay'));
                    if (this_.onScreen(trigger_elem) && !gsap.isTweening(trigger_elem) && !$(this).hasClass('actived')) {
                        gsap.to($(this), time, {
                            css: {
                                top: 0,
                                opacity: 1
                            },
                            delay: delay,
                            ease: ease
                        });
                        $(this).addClass('actived');
                    }
                    break;
                case "abs_from_top":
                    delay = parseFloat($(this).attr('delay'));
                    if (this_.onScreen(trigger_elem) && !gsap.isTweening(trigger_elem) && !$(this).hasClass('actived')) {
                        gsap.to($(this), time, {
                            css: {
                                top: 0,
                                opacity: 1                            
                            },
                            delay: delay,
                            ease: ease
                        });
                        $(this).addClass('actived');
                    }
                    break;
                case "from_bottom":
                    delay = parseFloat($(this).attr('delay'));
                    if (this_.onScreen(trigger_elem) && !gsap.isTweening(trigger_elem) && !$(this).hasClass('actived')) {
                        gsap.to($(this), time, {
                            css: {
                                bottom: 0,
                                opacity: 1
                            },
                            delay: delay,
                            ease: ease
                        })
                        $(this).addClass('actived');
                    }
                    break;
                case "abs_from_bottom":
                    delay = parseFloat($(this).attr('delay'));
                    if (this_.onScreen(trigger_elem) && !gsap.isTweening(trigger_elem) && !$(this).hasClass('actived')) {
                        gsap.to($(this), time, {
                            css: {
                                bottom: 0,
                                opacity: 1                            
                            },
                            delay: delay,
                            ease: ease
                        })
                        $(this).addClass('actived');
                    }
                    break;
                case "from_left":
                    delay = parseFloat($(this).attr('delay'));
                    if (this_.onScreen(trigger_elem) && !gsap.isTweening(trigger_elem) && !$(this).hasClass('actived')) {
                        gsap.to($(this), time, {
                            css: {
                                left: 0,
                                opacity: 1
                            },
                            delay: delay,
                            ease: ease
                        })
                        $(this).addClass('actived');
                    }
                    break;
                case "abs_from_left":
                    delay = parseFloat($(this).attr('delay'));
                    if (this_.onScreen(trigger_elem) && !gsap.isTweening(trigger_elem) && !$(this).hasClass('actived')) {
                        gsap.to($(this), time, {
                            css: {
                                left: 0,
                                opacity: 1                            
                            },
                            delay: delay,
                            ease: ease
                        })
                        $(this).addClass('actived');
                    }
                    break;
                case "from_right":
                    delay = parseFloat($(this).attr('delay'));
                    if (this_.onScreen(trigger_elem) && !gsap.isTweening(trigger_elem) && !$(this).hasClass('actived')) {
                        gsap.to($(this), time, {
                            css: {
                                right: 0,
                                opacity: 1
                            },
                            delay: delay,
                            ease: ease
                        })
                        $(this).addClass('actived');
                    }
                    break;
                case "abs_from_right":
                    delay = parseFloat($(this).attr('delay'));
                    if (this_.onScreen(trigger_elem) && !gsap.isTweening(trigger_elem) && !$(this).hasClass('actived')) {
                        gsap.to($(this), time, {
                            css: {
                                right: 0,
                                opacity: 1                            
                            },
                            delay: delay,
                            ease: ease
                        })
                        $(this).addClass('actived');
                    }
                    break;
                case "line_anim":
                    delay = parseFloat($(this).attr('delay'));
                    if (this_.onScreen(trigger_elem) && !gsap.isTweening(trigger_elem) && !$(this).hasClass('actived')) {
                        gsap.to($(this), time, {
                            css: {
                                width: '100%',
                                opacity: 1
                            },
                            delay: delay,
                            ease: ease
                        })
                        $(this).addClass('actived');
                    }
                    break;
            }
        });
    }
})
