import $ from 'jquery';
import 'slick-carousel'; 
import 'slick-carousel/slick/slick.css';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import helper from './libs/helper.js'; 
import animateFrom from './libs/animateFrom.js'; 
import parallax from './libs/parallax.js'; 
import { gsap, TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger); 

const animate = animateFrom($, gsap);
const home = {};

home.loader = function () { 
    var wrap = $(".loader"),
            wrap_bg = wrap.find('.innerLoad'),
            isiloader = wrap.find('.bg-load'),
            lines = wrap.find('.line-load span'),
            iload = 0,
            width = 0,
            load = $("body img").length;
    var anim = function() {
        TweenLite.to(wrap, 0.8, {opacity: 1, display: 'block', delay: 0, onComplete: function () {
                TweenLite.to(lines, 4.5, { 
                    width: '100%', 
                    delay: 0, 
                    onComplete: function () {                                                   
                        TweenLite.to(isiloader, .8, {
                            top: '100%', 
                            ease: "Power2.easeOut",
                            delay: 0
                        });
                        TweenLite.to(wrap_bg, .8, {
                            opacity:0,
                            ease: "Power2.easeOut", 
                            delay: 0
                        });
                        setTimeout(function () {
                            wrap.fadeOut('normal');
                        }, 2000);
                    }});
            }});
    }
    anim();
    if ($(".loader").length) {
        var delay = 0;
        $("body img").bind('load', function () {
            iload++;
            delay = iload;
            width = (iload / load) * 100;
            // vlo = 1;
            if (iload == load) {
                anim();
            }
        });
        anim();
    }   
}

home.bannerSlider = function () {
    const slider = $('.banner-slider'),
        caps = slider.find(".item");

    slider.slick({
        arrows:false,
        dots:true,
        fade:true,
        autoplay:true,
        pauseOnHover:false,
        pauseOnFocus:false,
        autoplaySpeed:5000
    });

    if(slider.length){
        var bg = slider.find(".slick-list .slick-slide picture > img");

        TweenLite.set(caps.find('h2'), {css: {'position': 'relative'}});
        TweenLite.set(caps.find('h3'), {css: {'position': 'relative'}});
        TweenLite.set(caps.find('.button'), {css: {'position': 'relative'}});
    }
    slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        var caption = slider.find(".slick-slide[data-slick-index=" + nextSlide + "]");
        var befores = slider.find(".slick-slide[data-slick-index=" + currentSlide + "]");

        TweenLite.to(caption.find('h2'), 0.2, {css: {'left': '-100px','opacity': '0',},ease: 'Quart.easeOut'});
        TweenLite.to(caption.find('h3'), 0.2, {css: {'right': '-50px','opacity': '0',},ease: 'Quart.easeOut'});
        TweenLite.to(caption.find('.button'), 0.2, {css: {'bottom': '-30px','opacity': '0',},ease: 'Quart.easeOut'});
    });
    slider.on('afterChange', function(event, slick, currentSlide){
        var caption = slider.find(".slick-slide[data-slick-index=" + currentSlide + "]");

        TweenLite.to(caption.find('h2'), 0.8, {css: {'left': '0','opacity': '1',},delay: 0.4,ease: 'Quart.easeOut'});
        TweenLite.to(caption.find('h3'), 0.8, {css: {'right': '0','opacity': '1',},delay: 0.6,ease: 'Quart.easeOut'});
        TweenLite.to(caption.find('.button'), 0.8, {css: {'bottom': '0','opacity': '1',},delay: 0.8,ease: 'Quart.easeOut'});
    });
}

home.ourProduct = function() {
    const slider = $('.our-product .product-slider');
    slider.slick({
        arrows: false,
        dots: false,
        speed:1000,
        infinite: false,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        responsive: [ 
            { 
                breakpoint: 991,
                settings: {
                    slidesToShow: 3.5
                }
            },
            { 
                breakpoint: 680,
                settings: {
                    slidesToShow: 2.8
                }
            }
        ],
    });
    if (window.innerWidth > 680) {
        setTimeout(function() {
            slider.on('setPosition', function(event,slick){
                slick.$slides.css('height', slick.$slideTrack.height() + 'px');
            });
        }, 700);
    }
}

home.promo = function() {
    const mCustomScrollbar =$(".mcs-horizontal");
    mCustomScrollbar.mCustomScrollbar({
		axis:"x",
		theme:"dark-3",
		setWidth: false, 
		advanced:{
			autoExpandHorizontalScroll:false //optional (remove or set to false for non-dynamic/static elements)
		}
	});

	var item = $('.mcs-horizontal .item').length;
    if (window.innerWidth > 1280) {
	   var itemWidth = $('.mcs-horizontal .item').width() + 30;
    }else{ 
       var itemWidth = $('.mcs-horizontal .item').width() + 20;
    }

	if ($('.mcs-horizontal .item')) {
		$('.mCSB_container').width(item * itemWidth);
	}
}

home.mousemoveEffect = function() { 
    var timeout;
        $('.our-product').mousemove(function(e){
        if(timeout) clearTimeout(timeout);
            setTimeout(callParallax.bind(null, e), 200);
        });

    function callParallax(e){
        parallaxIt(e, '.img-float-animation .left', -10);
        parallaxIt(e, '.img-float-animation .left-top', -15);
        parallaxIt(e, '.img-float-animation .left-center', -15);
        parallaxIt(e, '.img-float-animation .right-center', -10);
        parallaxIt(e, '.img-float-animation .right-top', -10);
        parallaxIt(e, '.img-float-animation .right', -10);
    }

    function parallaxIt(e, target, movement){
        var $this = $('.our-product');
        // var relX = e.pageX - $this.offset().left;
        var relY = e.pageY - $this.offset().top;

        gsap.to(target, 1, {
            // x: (relX - $this.width()/2) / $this.width() * movement,
            y: (relY - $this.height()/2) / $this.height() * movement,
            ease: 'Power2.easeOut'
        })
    }
}

home.animHome = function(){ 
    const   elAnimFromTop = $(".anim-from-top"),
            elAnimFromRight = $(".anim-from-right"),
            elAnimFromBottom = $(".anim-from-bottom"),
            elAnimFromLeft = $(".anim-from-left"),

            elAnimAbsFromTop = $(".abs-from-top"),
            elAnimAbsFromRight = $(".abs-from-right"),
            elAnimAbsFromBottom = $(".abs-from-bottom"),
            elAnimAbsFromLeft = $(".abs-from-left");

    animate.animationFrom(elAnimFromTop,{
        from: 'from_top',
        time: 0.5,
        ease: "Power2.easeOut" 
    });
    animate.animationFrom(elAnimFromRight,{
        from: 'from_right',
        time: 0.5,
        ease: "Power2.easeOut" 
    });
    animate.animationFrom(elAnimFromBottom,{
        from: 'from_bottom',
        time: 0.5,
        ease: "Power2.easeOut" 
    });
    animate.animationFrom(elAnimFromLeft,{
        from: 'from_left',
        time: 0.5,
        ease: "Power2.easeOut" 
    });
    
    animate.animationFrom(elAnimAbsFromTop,{
        from: 'abs_from_top',
        time: 0.5,
        ease: "Power2.easeOut" 
    }); 
    animate.animationFrom(elAnimAbsFromBottom,{
        from: 'abs_from_bottom',
        time: 0.5,
        ease: "Power2.easeOut" 
    });    
    animate.animationFrom(elAnimAbsFromLeft,{
        from: 'abs_from_left',
        time: 0.5,
        ease: "Power2.easeOut" 
    });   
    animate.animationFrom(elAnimAbsFromRight,{
        from: 'abs_from_right',
        time: 0.5,
        ease: "Power2.easeOut" 
    });
}

home.init = function() {
    // home.loader();
    home.bannerSlider();
    home.ourProduct();
    if (window.innerWidth > 1280) {
        home.animHome(); 
        home.mousemoveEffect(); 
    }
    setTimeout( function() {
        if (window.innerWidth > 680) {
            helper($).equalheight('.our-product .product-slider .item figure figcaption');
        }
    }, 200);
}

home.onload = function() {
    home.promo();
}

home.init();
home.onload();